import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IArtworXBase {
    id: number;
    productID: string;
    uploadID: string;
    height: number;
    width: number;
    pageHeader: string;
    created: Date;
    createdByUserID: string;
    lastUpdated: Date;
    lastUpdatedByUserID: string;
}

export class ArtworXBase implements IArtworXBase {

    constructor(data?: IArtworXBase) {
        if (data) this.update(data);
    }

    update(data: IArtworXBase): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    productID: string = utils.emptyGuidValue;
    uploadID: string = utils.emptyGuidValue;
    height: number = 0;
    width: number = 0;
    pageHeader: string = "";
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: string = utils.emptyGuidValue;

    isDirty: boolean = false;
}
