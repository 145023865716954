





















import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ArtworXDialogue from "@/vue/components/dialogues/ArtworXDialogue.vue";
import { ArtworXBaseVM, IArtworXBaseVM } from "@/model/Api/VM/ArtworXBaseVM";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";

@Component({
    components: { 
        ApiButton,
        ArtworXDialogue
    }
})

export default class ArtworX extends Vue {

    async mounted(): Promise<void> { 
        this.loadArtworX();
    }
    
    //
    // -- properties
    //

    private artworXHeaders = [
        { text: "Product", value: "product", sortable: true },
        { text: "Filename", value: "originalFilename", sortable: true },
        { text: "Page Header", value: "artworXBase.pageHeader", sortable: true },
        { text: "Created", value: "artworXBase.created", align: "center", sortable: false },
        { text: "Last Updated", value: "artworXBase.lastUpdated", sortable: false }
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new SearchParameters();
    private artworXList: Array<ArtworXBaseVM> = [];

    //
    // -- methods
    //

    private async loadArtworX() {
        this.artworXList = [];
        const response = await apiClient.get("/api/artworx/loadAll");
        this.artworXList.push(...response.map((a: IArtworXBaseVM) => new ArtworXBaseVM(a)));
    }

    addNew(): void {
        const dialog: ArtworXDialogue = this.$refs.artworXDialogue as ArtworXDialogue;
        dialog.add();
    }

    edit(artworX: ArtworXBaseVM): void {
        const dialog: ArtworXDialogue = this.$refs.artworXDialogue as ArtworXDialogue;
        dialog.edit(artworX.artworXBase.id);
    }
}
