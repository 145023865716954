import { ArtworXBase, IArtworXBase } from "../../ArtworXBase";
import { mapData } from "@/utilities/DataMapping";

export interface IArtworXBaseVM {
    artworXBase: IArtworXBase;
    product: string;
    originalFilename: string;
}

export class ArtworXBaseVM implements IArtworXBaseVM {

    constructor(data?: IArtworXBaseVM) {
        if (data) this.update(data);
    }

    update(data: IArtworXBaseVM): void {
        mapData(data, { 
            root: () => this,
            artworXBase: () => new ArtworXBase()
        });
    }

    artworXBase: ArtworXBase = new ArtworXBase();
    product: string = "";
    originalFilename: string = "";
}
