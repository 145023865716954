























































































import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import ConfirmDialogue from "@/vue/components/dialogues/ConfirmDialogue.vue";
import fileDownload from "@/utilities/FileDownload";
import { ArtworXBase } from "@/model/ArtworXBase";
import { Document, IDocument } from "@/model/Document";
import { Product, IProduct } from "@/model/Product";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";

@Component({ components: { ApiButton, ConfirmDialogue } })

export default class ArtworXDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("artworkForm") private readonly artworkForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private artworx: ArtworXBase = new ArtworXBase();
    private document: Document = new Document();
    private products: Array<Product> = [];
    private uploadDoc: File | null = null;

    private documentToDeleteID: string = utils.emptyGuidValue;

    //
    // -- Watchers
    //

    @Watch("artworx.height")
    private onHeightChanged() {
        this.artworx.isDirty = true;
    }

    @Watch("artworx.width")
    private onWidthChanged() {
        this.artworx.isDirty = true;
    }

    @Watch("artworx.pageHeader")
    private onPageHeaderChanged() {
        this.artworx.isDirty = true;
    }

    @Watch("artworx.productID")
    private onProductIDChanged() {
        this.artworx.isDirty = true;
    }

    @Watch("artworx.uploadID")
    private onUploadIDChanged() {
        this.artworx.isDirty = true;
    }

    //
    // -- methods
    //

    async add(): Promise<void> {
        this.reset;
        this.loadProducts();
        this.dialogueTitle = "Add New ArtworX";
        this.showDialogue = true;
    }

    async edit(id: number): Promise<void> {
        this.reset();
        this.loadProducts();
        this.dialogueTitle = "Edit ArtworX";
        await this.loadData(id);
        this.artworx.isDirty = false;
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.artworx);
        this.products = [];
        this.artworkForm?.resetValidation();
    }

    private async previewArtworX(id: number) {
        if (id == 0 || id == null) {
            toastr.warning("ArtworX Preview is not available.");
        } else {
            await fileDownload.download(`/api/artworx/previewArtworx?id=${id}`, `ArtworXPreview.pdf`);
        }
    }

    private async downloadDocument(imageID: string) {
        if (imageID == utils.emptyGuidValue || imageID == null) {
            toastr.warning("Image is not available.");
        } else {
            await fileDownload.download(`/api/document/downloadDocument?id=${imageID}`, this.document.originalFilename);
        }
    }

    deleteDocument(imageID: string): void {
        this.documentToDeleteID = imageID;

        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async doUploadDelete() {
        const response: ISaveResponse = await apiClient.get(`/api/document/documentDelete?id=${this.documentToDeleteID}`);
        if (response.isSuccess) {
            toastr.warning("Document deleted");
            this.artworx.uploadID = '00000000-0000-0000-0000-000000000000';
            this.uploadDoc = null;
        } else {
            toastr.warning("Failed to delete document");
            return;
        }

        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.hide();
    }

    private async save() {
        if (!this.artworkForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        
        if (this.uploadDoc != null && this.uploadDoc.size > 0 && this.uploadDoc.size <= 20000000) {
            const uploadResponse: { success: boolean; document: IDocument } = await apiClient.uploadFile("api/document/uploadDocument", this.uploadDoc);
            if (!uploadResponse.success) {
                toastr.warning("Failed to upload document");
                return;
            } else {
                const response: ISaveResponse = await apiClient.post("/api/document/saveDocument", uploadResponse.document);
                if (response.isSuccess) {
                    toastr.success("Image uploaded successfully");
                    this.artworx.uploadID = response.newID;
                } else {
                    toastr.warning("Failed to save image details");
                    return;
                }
            }
        } else if (this.uploadDoc != null && this.uploadDoc.size == 0 && this.uploadDoc.size > 20000000) {
            toastr.warning("Please check you have added a file to upload and it is under 20MB in size");
        }

        const saveResponse: ISaveResponse = await apiClient.post("/api/artworx/save", this.artworx, "artworx-save");
        if (saveResponse.isSuccess) {
            toastr.success("Saved");
            this.artworx.isDirty = false;
            this.$emit("refresh");
        } else {
            toastr.warning("Failed to Save");
        }
        
    }

    private async cancel() {
        if (this.artworx.isDirty) {
            const dialog: ConfirmDialogue = this.$refs.cancelDialogue as ConfirmDialogue;
        dialog.show();
        } else {
            this.showDialogue = false;
        }
    }

    doCancel(): void {
        this.showDialogue = false;
    }

    async loadData(id: number): Promise<void> {
        const responseData = await apiClient.get(`api/artworx/Load?id=${id}`);
        this.artworx.update(responseData.artworX);
        this.document.update(responseData.document);
    }

    private async loadProducts() {
        this.products = [];
        const response = await apiClient.get("/api/product/products");
        this.products.push(...response.map((p: IProduct) => new Product(p)));
    }
}
